import { JobRepairNoteDto, JobRepairNoteType } from '@vatos-pas/common';

export const getNoteType = (
  note: JobRepairNoteDto,
  isFirstDescription: boolean,
) => {
  switch (note.jobRepairNoteType) {
    case JobRepairNoteType.DESCRIPTION: {
      if (isFirstDescription) {
        return 'Description';
      } else {
        return 'Description Update';
      }
    }

    case JobRepairNoteType.NOTE:
      return 'Note';

    default:
      return 'Note';
  }
};
