import { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { flatten } from 'lodash';
import { luxon } from 'services/luxon';
import { useDataProvider, GetListResult, useNotify } from 'react-admin';
import {
  BoardPurchaseFloorDto,
  BoardTypeDto,
  ContractorDto,
} from '@vatos-pas/common';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import { axiosInstance } from 'services/http';

const useStyles = makeStyles({
  dialog: {
    width: '500px',
    '@media (max-width: 800px)': {
      width: 'auto',
    },
  },
  dateStarted: {
    marginTop: '0px',
  },
});

const convertFilters = (phaseName: string, regionId: string) => {
  const filter: {
    archived: boolean;
    active: boolean;
    suspended: boolean;
    'contractorRegions.regionId': string;
    hangerAvailable?: boolean;
    finisherAvailable?: boolean;
    sprayerAvailable?: boolean;
    bumperAvailable?: boolean;
  } = {
    active: true,
    suspended: false,
    archived: false,
    'contractorRegions.regionId': regionId,
  };

  const bumpPhases = ['Bump1', 'Bump2', 'Bump3', 'Bump4', 'Bump5'];

  if (phaseName === 'Hanging' || phaseName === 'Garage') {
    filter.hangerAvailable = true;
  } else if (phaseName === 'Finishing') {
    filter.finisherAvailable = true;
  } else if (phaseName === 'Spraying') {
    filter.sprayerAvailable = true;
  } else if (bumpPhases.includes(phaseName)) {
    filter.bumperAvailable = true;
  }

  return filter;
};

export const LogGarage = (props: any) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [boardType, setBoardType] = useState<BoardTypeDto[]>([]);
  const [boardTypeSelected, setBoardTypeSelected] = useState<string>('');
  const [boardQuantity, setBoardQuantity] = useState('');
  const [contractors, setContractors] = useState<ContractorDto[]>([]);
  const [contractorSelected, setContractorSelected] = useState('');
  const [completionDate, setCompletionDate] = useState(new Date());

  const isCompletionDateAfterToday = luxon.isAfter(
    luxon.date(completionDate),
    luxon.date(new Date()),
  );

  const flattenedBoardPurchaseFloors = flatten<BoardPurchaseFloorDto>(
    props.job.boardPurchases?.map(
      boardPurchase => boardPurchase.boardPurchaseFloors,
    ) || [],
  );

  const firstFloorBoardPurchases = flattenedBoardPurchaseFloors?.filter(
    boardPurchaseFloor => boardPurchaseFloor.floor === 1,
  );

  const firstFloorBoardTypeIds = firstFloorBoardPurchases?.map(
    board => board.boardTypeId,
  );

  const maxBoardQuantity = firstFloorBoardPurchases
    ?.filter(
      boardPurchaseFloor =>
        boardPurchaseFloor.boardTypeId === boardTypeSelected,
    )
    ?.reduce((acc, boardPurchaseFloor) => acc + boardPurchaseFloor.quantity, 0);

  const getBoardTypes = async () => {
    const boards = await dataProvider.getList<BoardTypeDto>('board-type', {
      filter: {
        'id||$in': firstFloorBoardTypeIds?.join(),
      },
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
    });

    if (boards?.data.length) {
      setBoardType(boards.data);
    }
  };

  const getContractors = async () => {
    const phaseType = props.job.phases.find(
      (phase: any) => phase.id === props.selectedJobPhase.phaseId,
    ).phaseType;
    const contractorData: GetListResult<ContractorDto> =
      await dataProvider.getList('contractor', {
        filter: {
          ...convertFilters(phaseType, props.regionId),
          $join: [{ field: 'contractorRegions' }],
        },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'name', order: 'ASC' },
      });
    if (contractorData.data) {
      setContractors(contractorData.data);
    } else {
      throw new Error('Job phases not found');
    }
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target;
    const numericValue = Number(inputValue);

    // Block input of '0' and empty input
    if (inputValue === '0' || inputValue === '') {
      setBoardQuantity('');
      return;
    }

    // Allow only valid numeric values
    const isValidNumber =
      !isNaN(numericValue) &&
      numericValue > 0 &&
      numericValue <= maxBoardQuantity;

    if (isValidNumber) {
      setBoardQuantity(inputValue);
    }
  };

  const logGarage = async () => {
    const contractorFound = contractors.find(
      contractor => contractor.id === contractorSelected,
    );

    if (!contractorFound) return;

    if (props.selectedJobPhase) {
      const completionDateFormatted = completionDate.toISOString();

      const params = {
        completionDate: completionDateFormatted,
        quantity: parseInt(boardQuantity, 10),
        jobPhaseId: props.selectedJobPhase.id,
        contractorId: contractorSelected,
        boardTypeId: boardTypeSelected,
      };

      return axiosInstance
        .post('job-phase-garage', params)
        .then(() => {
          notify('Garage logged!');
          props.refreshJobPhases();
          props.handleClose();
        })
        .catch(error => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            notify(
              `Garage Log Error: ${error.response.data.message}`,
              'warning',
            );
          } else {
            notify(`Garage Log Error: ${error.message}`, 'warning');
          }
        });
    }
  };

  useEffect(() => {
    if (props.selectedJobPhase) {
      getContractors();
      getBoardTypes();
    }
  }, [props.selectedJobPhase]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-title">Log Garage</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Fragment>
          <Box display="flex">
            <Typography>Select a Contractor</Typography>
          </Box>
          <Box>
            <Box display="flex">
              <Select
                onChange={(event: any) =>
                  setContractorSelected(event.target.value)
                }
                labelId={`custom-reference-contractor`}
                id={`reference-contractor`}
                fullWidth
                value={contractorSelected}
              >
                <MenuItem key="Select" value="" data-item="" data-name="select">
                  Select a contractor
                </MenuItem>
                {contractors.map((item: ContractorDto) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    data-item={item.id}
                    data-name={item.name}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" mt={2}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Completion Date"
              fullWidth
              maxDate={new Date()}
              value={completionDate}
              onChange={selected => {
                if (!selected) return;
                setCompletionDate(selected.toJSDate());
              }}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
            />
          </Box>

          <Box display="flex" mt={2}>
            <Typography>Board Type</Typography>
          </Box>
          <Box display="flex">
            <FormControl fullWidth>
              <Select
                onChange={(event: any) => {
                  setBoardQuantity('');
                  setBoardTypeSelected(event.target.value);
                }}
                labelId="custom-reference-board-type"
                id="reference-board-type"
                fullWidth
                inputProps={{
                  'aria-label':
                    'The available Board Types are those listed for Floor 1 in the Purchase Order',
                }}
                value={boardTypeSelected}
              >
                {boardType.map(item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    data-item={item.id}
                    data-name={item.shortName}
                  >
                    {item.shortName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                The available Board Types are those listed for Floor 1 in the
                Purchase Order
              </FormHelperText>
            </FormControl>
          </Box>

          <Box display="flex" mt={2}>
            <Typography>Board Quantity</Typography>
          </Box>
          <Box display="flex">
            <TextField
              fullWidth
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                  max: maxBoardQuantity,
                },
              }}
              helperText="The Board Quantity can’t exceed the amount in the Purchase Order"
              disabled={!boardTypeSelected}
              value={boardQuantity}
              onChange={handleQuantityChange}
            />
          </Box>
        </Fragment>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button
            onClick={() => {
              setContractorSelected('');
              props.handleClose();
            }}
            color="primary"
          >
            Cancel request
          </Button>

          <Button
            onClick={logGarage}
            color="primary"
            disabled={
              !contractorSelected ||
              !completionDate ||
              !boardTypeSelected ||
              !boardQuantity ||
              isCompletionDateAfterToday
            }
          >
            Submit request
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LogGarage;
