/* eslint-disable no-debugger */
import React, { useEffect, useState, Fragment } from 'react';
import { useDataProvider } from 'react-admin';
import {
  BoardFloorDto,
  BoardTypeDto,
  BoardFloorInterface,
} from '@vatos-pas/common';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './master-sheet-styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FloorListItem, FloorListRow } from './master-sheet-edit';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_SIGN_OFF, CAN_CHECK_COUNT } from 'providers/permissions';
import CheckCountDialog from './master-sheet-check-count';

interface MasterSheetBoardTableProps {
  boardPurchase: any;
  signReport: any;
  boardReceiptFloors: any;
  history: any;
  theJob: any;
}

const getBoardTypes = async (boardPurchase, dataProvider, setBoardTypes) => {
  const boardTypeIds = [
    ...new Set(
      boardPurchase.boardPurchaseFloors.map(
        boardFloor => boardFloor.boardTypeId,
      ),
    ),
  ];

  const responses = await Promise.all(
    boardTypeIds.map((id: any) => dataProvider.getOne('board-type', { id })),
  );

  if (!responses) throw new Error('Board types not found');

  const boardTypes = responses
    .map(res => res.data)
    .sort((a, b) => a.sortPriority - b.sortPriority);

  setBoardTypes(boardTypes);
};

const getFloors = (boardPurchase, setFloors) => {
  let floorList;

  if (boardPurchase.boardPurchaseFloors) {
    const floors = [
      ...new Set(
        boardPurchase.boardPurchaseFloors.map(
          (boardFloor: any) => boardFloor.floor,
        ),
      ),
    ].sort();

    floorList = floors.map((boardFloor: any) => ({
      floor: boardFloor,
      rows: [{ row: 1, boards: {} }],
    }));
    setFloors(floorList);
  }
};

const addFloorRow =
  (floor: BoardFloorInterface['floor'], floors: any, setFloors: any) => () => {
    const newFloors = [...floors];
    const floorFound = newFloors.find(
      (floorItem: FloorListItem) => floorItem.floor === floor,
    );
    if (floorFound) {
      floorFound.rows.push({ row: floorFound.rows.length + 1, boards: {} });
      setFloors(newFloors);
    }
  };

const removeFloorRow =
  (
    floor: BoardFloorInterface['floor'],
    rowIndex: number,
    floors: any,
    setFloors: any,
  ) =>
  () => {
    const newFloors = [...floors];
    const floorListItem = newFloors.find(
      (floorListItem: FloorListItem) => floorListItem.floor === floor,
    );
    if (floorListItem) {
      floorListItem.rows.splice(rowIndex, 1);
    }
    setFloors(newFloors);
  };

const totalBoardsSum = (floors: any) => {
  let count = 0;
  floors.map((floor: FloorListItem) => {
    return floor.rows.map((row: FloorListRow) => {
      const keys = Object.keys(row.boards);
      return keys.map(key => {
        if (row.boards[key] && row.boards[key].quantity) {
          const string = row.boards[key].quantity.toString();
          count = count + parseInt(string);
        }
      });
    });
  });
  return count;
};

const isSameBoardsQuantity = (
  boardPurchases: any,
  floors: any,
  boardReceipts: any,
) => {
  if (boardReceipts.length) {
    let sumPurchase = 0;
    boardPurchases.boardPurchaseFloors.map((floor: any) => {
      sumPurchase += floor.quantity;
    });
    let sumReceipt = 0;
    boardReceipts.map((boardReceipt: BoardFloorDto) => {
      sumReceipt += boardReceipt.quantity;
    });
    return sumPurchase === sumReceipt;
  }
  if (boardPurchases.boardPurchaseFloors) {
    const sumFilled: number = totalBoardsSum(floors);
    let sumPurchase = 0;
    boardPurchases.boardPurchaseFloors.map((floor: BoardFloorDto) => {
      sumPurchase += floor.quantity;
    });
    return sumFilled === sumPurchase;
  } else {
    throw new Error('No board purchase floors were found on board purchase');
  }
};

const setBoardQuantity =
  (
    floor: FloorListItem,
    row: FloorListRow,
    boardType: any,
    floors: any,
    setFloors: any,
  ) =>
  (event: any) => {
    const newFloors = [...floors];
    const floorFound = newFloors.find(
      (floorItem: FloorListItem) => floorItem.floor === floor.floor,
    );
    if (floorFound) {
      const rowFound = floorFound.rows.find(
        (floorListRow: FloorListRow) => floorListRow.row === row.row,
      );
      if (rowFound) {
        const boardItem = rowFound.boards[boardType.id];
        if (!boardItem) {
          rowFound.boards[boardType.id] = {
            boardTypeId: boardType.id,
            quantity: parseInt(event.target.value),
            floor: floor.floor,
          };
        } else {
          boardItem.quantity = event.target.value;
        }
        setFloors(newFloors);
      }
    }
  };

const getBoardQuantity = (
  row: FloorListRow,
  boardType: BoardTypeDto,
  floor: BoardFloorInterface['floor'],
  floors: any,
) => {
  const floorFound = floors.find(
    (floorItem: FloorListItem) => floorItem.floor === floor,
  );
  if (floorFound) {
    const rowFound = floorFound.rows.find(rowItem => rowItem.row === row.row);
    if (rowFound) {
      const itemFound = rowFound.boards[boardType.id];
      return itemFound ? itemFound.quantity : 0;
    }
  }
  return 0;
};

const sumColumn = (floors: any, boardType: BoardTypeDto) => {
  let count = 0;
  floors.map((floor: FloorListItem) => {
    floor.rows.map((row: FloorListRow) => {
      if (row.boards[boardType.id] && row.boards[boardType.id].quantity) {
        const string = row.boards[boardType.id].quantity.toString();
        count = count + parseInt(string);
      }
    });
  });
  return count;
};

const getBoardReceipts = (boardReceiptFloors: any, boardPurchase: any) =>
  boardReceiptFloors.filter(
    (boardReceiptFloor: any) =>
      boardReceiptFloor.boardPurchaseId === boardPurchase.id,
  );

export const MasterSheetBoardTable: React.FC<MasterSheetBoardTableProps> = ({
  boardPurchase,
  signReport,
  boardReceiptFloors,
  history,
  theJob,
}) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [boardTypes, setBoardTypes] = useState([]);
  const [floors, setFloors] = useState([]);
  const [openCheckCount, setOpenCheckCount] = useState(false);
  const [boardReceipts] = useState(
    getBoardReceipts(theJob.boardReceiptFloors, boardPurchase),
  );

  useEffect(() => {
    getBoardReceipts(boardReceiptFloors, boardPurchase);
  }, [theJob.boardReceiptFloors, boardPurchase]);

  useEffect(() => {
    getBoardTypes(boardPurchase, dataProvider, setBoardTypes);
    getFloors(boardPurchase, setFloors);
  }, []);

  let floorsMap = floors;
  if (boardReceipts.length) {
    const floorsReceipt: any = [];
    boardReceipts
      .filter((item: any) => item.quantity > 0)
      .forEach(item => {
        const floorReceiptFound: any = floorsReceipt.find(
          (floor: any) => floor.floor === item.floor,
        );
        if (floorReceiptFound) {
          const alreadyExist = floorReceiptFound.rows.filter(
            row => row.boards[item.boardTypeId],
          ).length;
          if (alreadyExist > 0) {
            floorReceiptFound.rows.push({
              row: alreadyExist + 1,
              boards: {
                [item.boardTypeId]: {
                  boardTypeId: item.boardTypeId,
                  quantity: item.quantity,
                  floor: item.floor,
                },
              },
            });
          } else {
            floorReceiptFound.rows[0].boards[item.boardTypeId] = {
              boardTypeId: item.boardTypeId,
              quantity: item.quantity,
              floor: item.floor,
            };
          }
        } else {
          const newFloorReceipt = {
            floor: item.floor,
            rows: [
              {
                row: 1,
                boards: {
                  [item.boardTypeId]: {
                    boardTypeId: item.boardTypeId,
                    quantity: item.quantity,
                    floor: item.floor,
                  },
                },
              },
            ],
          };
          floorsReceipt.push(newFloorReceipt);
        }
      });
    floorsMap = floorsReceipt;
  }

  const { hasPermission } = PermissionsProvider.useContainer();

  const sum = function (items, prop) {
    return items.reduce(function (a, b) {
      return a + b[prop];
    }, 0);
  };

  const isCountedWrong = boardType => {
    if (!boardReceipts.length) {
      return false;
    }
    const originalPO = sum(
      boardPurchase.boardPurchaseFloors.filter(
        boardReceipt => boardReceipt.boardTypeId === boardType.id,
      ),
      'quantity',
    );
    const counted = sum(
      theJob.boardReceiptFloors.filter(
        boardReceipt => boardReceipt.boardTypeId === boardType.id,
      ),
      'quantity',
    );
    return originalPO !== counted;
  };

  const getMudCount = po => {
    const mud = po.materials.find(
      (material: any) => material.name.toLowerCase() === 'mud',
    );
    if (mud) {
      return mud.amount;
    }
    return 0;
  };

  // if (!boardPurchase.isPrimary) {
  //   return (
  //     <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="center"
  //       padding={6}
  //     >
  //       <Typography>For reference only</Typography>
  //     </Box>
  //   );
  // }
  return (
    <Fragment>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {theJob.boardReceiptFloors.length && hasPermission(CAN_CHECK_COUNT) ? (
          <Button
            color="primary"
            variant="outlined"
            disabled={!boardPurchase.isPrimary}
            size="large"
            className={classes.button}
            onClick={() => setOpenCheckCount(true)}
          >
            Check Count
          </Button>
        ) : (
          ''
        )}
        {theJob.boardReceiptFloors.length || !hasPermission(CAN_SIGN_OFF) ? (
          ''
        ) : (
          <Fragment>
            <Button
              color="primary"
              variant="outlined"
              disabled={!boardPurchase.isPrimary}
              size="large"
              className={classes.button}
              onClick={() => signReport(floors, boardPurchase)}
            >
              Sign Off
            </Button>
          </Fragment>
        )}
        <Typography
          className={
            isSameBoardsQuantity(boardPurchase, floors, boardReceipts)
              ? classes.totalBoardsGreen
              : classes.totalBoards
          }
        >
          {totalBoardsSum(floorsMap)}
        </Typography>
      </Box>
      <Box display="flex" width="100%">
        <Box display="flex" width="20%" minWidth="20%" justifyContent="center">
          <Typography>Floor</Typography>
        </Box>
        {boardTypes.length > 0 &&
          boardTypes.map((boardType: any) => (
            <Box
              key={boardType.id}
              display="flex"
              width={`${79 / boardTypes.length + 1}%`}
              maxWidth="150px"
              justifyContent="center"
              className={isCountedWrong(boardType) ? classes.contedWrong : ''}
            >
              <Typography>{boardType.shortName}</Typography>
            </Box>
          ))}
        <Box
          display="flex"
          width={`${79 / boardTypes.length + 1}%`}
          maxWidth="150px"
          justifyContent="center"
        >
          <Typography>Mud</Typography>
        </Box>
      </Box>
      <Box display="flex" width="100%" flexDirection="column">
        {floorsMap.length > 0 &&
          floorsMap.map((floor: any, floorIndex: number) => (
            <Box key={floor.floor} className={classes.floorGroup}>
              {floor.rows.map((row: any, i: number) => (
                <Box
                  display="flex"
                  width="100%"
                  key={floor.floor}
                  className={classes.floorRow}
                >
                  <Box
                    display="flex"
                    width="20%"
                    minWidth="20%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {i === 0 ? (
                      <Fragment>
                        <Typography>Floor #{floor.floor}</Typography>
                        {theJob.boardReceiptFloors.length ||
                        !hasPermission(CAN_SIGN_OFF) ||
                        !boardPurchase.isPrimary ? (
                          ''
                        ) : (
                          <AddCircleIcon
                            onClick={addFloorRow(
                              floor.floor,
                              floorsMap,
                              setFloors,
                            )}
                            className={classes.pointer}
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {theJob.boardReceiptFloors.length ||
                        !hasPermission(CAN_SIGN_OFF) ||
                        !boardPurchase.isPrimary ? (
                          ''
                        ) : (
                          <RemoveCircleIcon
                            className={classes.pointer}
                            onClick={removeFloorRow(
                              floor.floor,
                              i,
                              floorsMap,
                              setFloors,
                            )}
                          />
                        )}
                      </Fragment>
                    )}
                  </Box>
                  {boardTypes.map((boardType: any) => (
                    <Box
                      key={boardType.id}
                      display="flex"
                      width={`${79 / boardTypes.length}%`}
                      maxWidth="150px"
                      justifyContent="center"
                    >
                      <TextField
                        type="number"
                        className={classes.inputGrid}
                        placeholder="0"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={getBoardQuantity(
                          row,
                          boardType,
                          floor.floor,
                          floorsMap,
                        )}
                        onChange={setBoardQuantity(
                          floor,
                          row,
                          boardType,
                          floorsMap,
                          setFloors,
                        )}
                        disabled={
                          !hasPermission(CAN_SIGN_OFF) ||
                          (theJob.boardReceiptFloors &&
                            theJob.boardReceiptFloors.length > 0) ||
                          !boardPurchase.isPrimary
                        }
                      />
                    </Box>
                  ))}
                  {floorIndex === 0 && i === 0 && (
                    <Box
                      display="flex"
                      width={`${79 / boardTypes.length + 1}%`}
                      maxWidth="150px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography>{getMudCount(boardPurchase)}</Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          ))}
      </Box>
      <Box display="flex" width="100%" className={classes.floorGroup} pt={2}>
        <Box
          display="flex"
          width="20%"
          minWidth="20%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>Total</Typography>
        </Box>
        {boardTypes.length &&
          boardTypes.map((boardType: any) => (
            <Box
              key={boardType.id}
              display="flex"
              width={`${79 / boardTypes.length}%`}
              maxWidth="150px"
              justifyContent={floors.length > 0 ? 'flex-end' : 'center'}
            >
              <TextField
                disabled
                className={classes.inputGrid}
                value={sumColumn(floorsMap, boardType)}
              />
            </Box>
          ))}
      </Box>
      <CheckCountDialog
        boardPurchase={boardPurchase}
        boardPurchaseFloors={boardPurchase.boardPurchaseFloors}
        boardReceiptFloors={boardReceiptFloors}
        open={openCheckCount}
        handleClose={() => setOpenCheckCount(false)}
        history={history}
        disabled={!boardPurchase.isPrimary}
      />
    </Fragment>
  );
};

export default MasterSheetBoardTable;
