/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Fragment,
  FC,
  useState,
  cloneElement,
  ReactElement,
  useEffect,
} from 'react';
import {
  DateField,
  List,
  TextField,
  useDataProvider,
  useRefresh,
  useNotify,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  ExportButton,
  PublicFieldProps,
  InjectedFieldProps,
  ToolbarProps,
  ExportButtonProps,
  ListProps,
  Record,
} from 'react-admin';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import UploadIcon from '@material-ui/icons/CloudUpload';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import NoteIcon from '@material-ui/icons/Note';
import ConfirmDialog from 'components/ConfirmDialog';
import Box from '@material-ui/core/Box';
import Datagrid from 'components/Datagrid';
import { RepairReceivablesSheetFilter } from '../filters/repair-receivables-sheet-filters';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { exporter } from 'providers/export';
import {
  JobPhaseDto,
  JobRepairInterface,
  RepairReceivablesSheetInterface,
} from '@vatos-pas/common';
import {
  CAN_ADD_REPAIR_NOTES,
  CAN_EDIT_PO_RELEASED,
  CAN_PAID_PHASE,
} from 'providers/permissions';
import InfoDialog from 'components/InfoDialog';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Show } from 'components/Show';
import { PermissionsProvider } from 'providers/permissionsProvider';
import MasterSheetRepairPhotoUpload from './master-sheet-repair-photo-upload';
import MasterSheetRepairNotes from './master-sheet-repair-notes';
import { getAddContractorsBlocked } from 'modules/master-sheet/utils/getAddContractorsBlocked';
import { Repair } from 'modules/master-sheet/components';

const MAX_REPAIR_DAYS_LATE = 7;

const Supervisor: FC<
  PublicFieldProps & InjectedFieldProps<RepairReceivablesSheetInterface>
> = props => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box display="flex" gridColumnGap={8}>
      <label>{`${props.record?.originalSupervisorUserFirstName} ${props.record?.originalSupervisorUserLastName}`}</label>
      <Show
        condition={
          props.record?.originalSupervisorUserId !==
          props.record?.supervisorUserId
        }
      >
        <IconButton onClick={() => setIsModalOpen(true)}>
          <InfoOutlinedIcon className={classes.infoWarning} />
        </IconButton>
      </Show>
      <InfoDialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        content={`Original Creator: ${props.record?.originalSupervisorUserFirstName} ${props.record?.originalSupervisorUserLastName}\nCurrent Supervisor: ${props.record?.supervisorFirstName} ${props.record?.supervisorLastName}`}
      />
    </Box>
  );
};

interface DefaultFieldsInterface {
  [id: string]: {
    poTotalOverride: number;
    check: string;
    readyToBook: boolean;
    internalInvoice: string;
  };
}

type PhotosFieldProps = {
  selectEmailModal: (id: string | undefined) => void;
} & PublicFieldProps &
  InjectedFieldProps<RepairReceivablesSheetInterface>;

const PhotosField: FC<PhotosFieldProps> = props => {
  const classes = useStyles(props);

  return (
    <Box display="flex" minWidth={80}>
      <IconButton
        onClick={() => {
          props.selectEmailModal(props.record?.id);
        }}
        className={classes.iconsUpload}
      >
        {props.record?.hasPhotos ? <CameraAltIcon /> : <UploadIcon />}
      </IconButton>
    </Box>
  );
};

type POReleasedProps = PublicFieldProps &
  InjectedFieldProps<RepairReceivablesSheetInterface>;

const POReleasedField: FC<POReleasedProps> = props => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { refetch } = useListContext();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [status, setStatus] = useState(!!props.record?.poReleased);

  const canEdit = hasPermission(CAN_EDIT_PO_RELEASED);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.record?.id) return;
    setStatus(event.target.checked);
    try {
      const params = {
        id: props.record.id,
        data: {
          poReleased: event.target.checked,
        },
        previousData: props.record,
      };

      await dataProvider.update('job-repair', params);
      notify('Changes were made successfully!');
      refetch();
    } catch (error) {
      notify(error.message, 'warning');
      setStatus(!event.target.checked);
    }
  };

  // React-admin uses stale-while-revalidate technique
  // Because of that, the first render of this component will always have the old state.
  // We need to update when react-admin passes the revalidated version of record.
  useEffect(() => {
    setStatus(!!props.record?.poReleased);
  }, [props.record]);

  if (!props.record?.id) return null;

  return (
    <Box display="flex" minWidth={80}>
      <Checkbox
        checked={status}
        disabled={
          (Number(props.record?.poTotal) >= 750 &&
            props.record?.hasContractorAssigned) ||
          !canEdit
        }
        onChange={handleChange}
      />
    </Box>
  );
};

type ReadyToBookFieldProps = {
  canPaid: boolean;
  fields: DefaultFieldsInterface;
  onChangeField: (
    id: string,
  ) =>
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
} & PublicFieldProps &
  InjectedFieldProps<RepairReceivablesSheetInterface>;

const ReadyToBookField: FC<ReadyToBookFieldProps> = props => {
  if (!props.record?.id) return null;

  const value =
    props.fields[props.record.id] &&
    props.fields[props.record.id].readyToBook !== undefined
      ? props.fields[props.record.id].readyToBook
      : props.record?.readyToBook
      ? props.record?.readyToBook
      : false;
  return (
    <Box display="flex" minWidth={80}>
      <Checkbox
        checked={value}
        disabled={!props.canPaid}
        onChange={props.onChangeField(props.record.id)}
      />
    </Box>
  );
};

type PoTotalOverrideFieldProps = {
  canPaid: boolean;
  fields: DefaultFieldsInterface;
  onChangeField: (
    id: string,
  ) =>
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
} & PublicFieldProps &
  InjectedFieldProps<RepairReceivablesSheetInterface>;

const PoTotalOverrideField: FC<PoTotalOverrideFieldProps> = props => {
  if (!props.record?.id) return null;

  const value =
    props.fields[props.record.id] &&
    props.fields[props.record.id].poTotalOverride !== undefined
      ? props.fields[props.record.id].poTotalOverride
      : props.record?.poTotalOverride
      ? props.record?.poTotalOverride
      : '';

  return (
    <Box display="flex" minWidth={80}>
      <Input
        value={value}
        fullWidth
        disabled={!props.canPaid}
        onChange={props.onChangeField(props.record.id)}
      />
    </Box>
  );
};

type InternalInvoiceFieldProps = {
  canPaid: boolean;
  fields: DefaultFieldsInterface;
  onChangeField: (
    id: string,
  ) =>
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
} & PublicFieldProps &
  InjectedFieldProps<RepairReceivablesSheetInterface>;

const InternalInvoiceField: FC<InternalInvoiceFieldProps> = props => {
  if (!props.record?.id) return null;

  const value =
    props.fields[props.record?.id] &&
    props.fields[props.record?.id].internalInvoice !== undefined
      ? props.fields[props.record?.id].internalInvoice
      : props.record?.internalInvoice
      ? props.record?.internalInvoice
      : '';
  return (
    <Box display="flex" minWidth={80}>
      <Input
        value={value}
        fullWidth
        disabled={!props.canPaid}
        onChange={props.onChangeField(props.record.id)}
      />
    </Box>
  );
};

type SubmitButtonProps = {
  fields: DefaultFieldsInterface;
  onClickSubmit: (record: RepairReceivablesSheetInterface) => () => void;
} & PublicFieldProps &
  InjectedFieldProps<RepairReceivablesSheetInterface>;

const SubmitButton: FC<SubmitButtonProps> = props => {
  if (!props?.record) return null;

  const isDisabled = () => {
    if (!props?.record) return;

    const fields = props.fields[props.record.id];
    return (
      (((fields && fields.poTotalOverride) ||
        (props.record.poTotalOverride &&
          fields &&
          fields.poTotalOverride === undefined)) &&
        fields &&
        fields.internalInvoice) ||
      (fields && fields.readyToBook !== props.record.readyToBook) ||
      (props.record.internalInvoice &&
        fields &&
        fields.internalInvoice === undefined)
    );
  };

  return (
    <Button
      disabled={!isDisabled()}
      variant="contained"
      color="primary"
      onClick={props.onClickSubmit(props.record)}
    >
      {props.record.internalInvoice || props.record.poTotalOverride
        ? 'Update'
        : 'Submit'}
    </Button>
  );
};

const useStyles = makeStyles({
  content: {
    // '@media(max-width: 1300px)': {
    overflow: 'auto',
    // },
  },
  root: {
    // '@media(max-width: 1300px)': {
    width: 'calc(100vw - 150px)',
    // },
  },
  infoWarning: {
    color: '#ff9800',
  },
  link: {
    backgroundColor: '#e6005a',
    height: '50px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  linkText: {
    color: 'white',
  },
  pink: {
    backgroundColor: 'lightpink',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  normal: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  iconsUpload: {
    cursor: 'pointer',
  },
});

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const RepairsNotes = props => {
  const hasJobRepairNotes = props.record.hasJobRepairNotes;

  return (
    <>
      {hasJobRepairNotes || !props.canPaid ? (
        <NoteIcon
          onClick={
            hasJobRepairNotes
              ? () => props.openNotes(props.record?.id)
              : undefined
          }
          style={{
            cursor: hasJobRepairNotes ? 'pointer' : 'not-allowed',
            opacity: hasJobRepairNotes ? 1 : 0.7,
          }}
        />
      ) : (
        <NoteAddIcon
          onClick={() => props.openNotes(props.record?.id)}
          style={{ cursor: 'pointer' }}
        />
      )}
    </>
  );
};

export const RepairReceivablesSheet: FC<ListProps> = props => {
  const [fields, setFields] = useState<DefaultFieldsInterface>({});
  const [idSelected, setIdSelected] = useState<JobPhaseDto['id']>('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [selectedReceivable, setSelectedReceivable] = useState<string>();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles(props);

  const { hasPermission } = PermissionsProvider.useContainer();

  const canPaid = hasPermission(CAN_PAID_PHASE);
  const canAddNotes = hasPermission(CAN_ADD_REPAIR_NOTES);

  const onClose = () => setOpenConfirm(false);

  const datagridRowStyle = (record: Record) => {
    const isAddContractorsBlocked = getAddContractorsBlocked(record as Repair);

    if (isAddContractorsBlocked) {
      return {
        backgroundColor: '#ff8282',
      };
    }

    if (record?.repairDaysLateCount >= MAX_REPAIR_DAYS_LATE) {
      return {
        backgroundColor: '#ffc107',
      };
    }
  };

  const onChangeField = field => id => event => {
    setFields({
      ...fields,
      [id]: { ...fields[id], [field]: event.target.value },
    });
  };

  const onChangeCheck = field => id => event => {
    setFields({
      ...fields,
      [id]: { ...fields[id], [field]: event.target.checked },
    });
  };

  const submitCost = (record: RepairReceivablesSheetInterface) => () => {
    setIdSelected(record.id);
    setOpenConfirm(true);
  };

  const onConfirmSubmit = async () => {
    try {
      const repairPaymentFound = await dataProvider.getOne<JobRepairInterface>(
        'job-repair',
        {
          id: idSelected,
        },
      );

      const poTotalOverride =
        fields[idSelected] && fields[idSelected].poTotalOverride
          ? parseFloat(fields[idSelected]?.poTotalOverride.toString())
          : parseFloat(repairPaymentFound.data.poTotal.toString());

      const internalInvoice =
        fields[idSelected] && fields[idSelected].internalInvoice
          ? fields[idSelected].internalInvoice
          : repairPaymentFound.data.internalInvoice;

      const readyToBook = fields[idSelected]
        ? fields[idSelected].readyToBook
        : repairPaymentFound.data.readyToBook;

      const params = {
        id: idSelected,
        data: {
          poTotalOverride,
          internalInvoice,
          readyToBook,
        },
        previousData: repairPaymentFound.data,
      };

      await dataProvider.update('job-repair', params);
      notify('Changes were made successfully!');
      refresh();
    } catch (e: unknown) {
      const msg = e instanceof Error ? e.message : 'An error has occurred!';

      notify(msg, 'warning');
    }
    setOpenConfirm(false);
  };

  const selectEmailModal = (id: string | undefined) => {
    setSelectedReceivable(id);
    setUploadModalOpen(true);
  };

  const openModalNotes = id => {
    setSelectedReceivable(id);
    setNotesModalOpen(true);
  };

  return (
    <Fragment>
      <List
        classes={{
          content: classes.content,
          root: classes.root,
        }}
        {...props}
        exporter={exporter('prod-pay-master-sheet')}
        filters={
          <RepairReceivablesSheetFilter>
            {props.filters}
          </RepairReceivablesSheetFilter>
        }
        filterDefaultValues={{
          'status||$eq': 'Unpaid',
        }}
        sort={{ field: 'repairDate', order: 'DESC' }}
        bulkActionButtons={<React.Fragment />}
        actions={<ListActions maxResults={20000} />}
      >
        <Datagrid rowStyle={datagridRowStyle}>
          <Supervisor label="Original Creator" />

          <TextField source="repairType" label="Repair Type" />
          <DateField source="repairDate" label="PO Request Date" />
          <TextField source="repairDaysLateCount" label="Days Late" />
          <RepairsNotes
            label="Repair Notes"
            openNotes={openModalNotes}
            canPaid={canPaid}
          />
          <TextField source="description" label="Description" />
          <TextField source="builderName" label="Builder" />
          <TextField source="subdivisionName" label="Subdivision" />
          <TextField source="jobLot" label="Lot" />
          <TextField source="jobBlock" label="Block" />
          <TextField source="poTotal" label="PO Total" />
          <PoTotalOverrideField
            canPaid={canPaid}
            onChangeField={onChangeField('poTotalOverride')}
            fields={fields}
            label="PO Total Override"
          />
          <PhotosField selectEmailModal={selectEmailModal} label="Uploads" />
          <InternalInvoiceField
            canPaid={canPaid}
            onChangeField={onChangeField('internalInvoice')}
            fields={fields}
            label="Internal Invoice #"
          />
          <POReleasedField label="PO Released" />
          <ReadyToBookField
            canPaid={canPaid}
            onChangeField={onChangeCheck('readyToBook')}
            fields={fields}
            label="Ready to book"
          />
          {canPaid && (
            <SubmitButton fields={fields} onClickSubmit={submitCost} />
          )}
        </Datagrid>
      </List>
      <ConfirmDialog
        open={openConfirm}
        title={`Are you sure that you want to make this change?`}
        handleClose={onClose}
        onConfirm={onConfirmSubmit}
      />
      {uploadModalOpen && (
        <MasterSheetRepairPhotoUpload
          canPaid={canPaid}
          // setPhotosStaged={stagePhoto}
          // setPhotosStagedEmail={stagePhotoEmail}
          // photosStagedEmail={photosStagedEmail}
          // photosStaged={photosStaged}
          selectedReceivable={selectedReceivable}
          open={uploadModalOpen}
          handleClose={() => setUploadModalOpen(false)}
          // type={type}
        />
      )}
      <MasterSheetRepairNotes
        selectedReceivable={selectedReceivable}
        handleClose={() => {
          setNotesModalOpen(false);
          setSelectedReceivable('');
        }}
        open={notesModalOpen}
        refresh={refresh}
        canAddNotes={canAddNotes}
      />
    </Fragment>
  );
};
